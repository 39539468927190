import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { HouseEnergyServicev4 } from "./house-energy-v4.service";
import { HomeService } from "../home/home.service";
import { ToastrService } from "ngx-toastr";
import { Constants } from "../../app.constant";

@Component({
  selector: "app-house-energy-v4",
  templateUrl: "./house-energy-v4.component.html",
  styleUrls: ["./house-energy-v4.component.scss"],
})
export class HouseEnergyv4Component implements OnInit, OnChanges {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() leadId: string;
  @Input() yearly: string;
  data;
  electrics;
  electricId;
  edit = 0;
  electricsupplier;

  constructor(
    private houseEnergy: HouseEnergyServicev4,
    private appConstants: Constants,
    private homeService: HomeService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.electrics = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.leadId &&
      this.leadId !== "" &&
      this.yearly &&
      this.yearly !== ""
    ) {
      this.getElectricSupplier();
    }
  }

  public submitForm(): void {
    this.onSubmit.emit({
      value: this.electricId,
    });
  }
  getElectricSupplier() {
    this.houseEnergy
      .getElectricSupplier({
        email: this.appConstants.email,
        lead_id: this.leadId,
        action: "getsupplierdata",
      })
      .subscribe((res) => {
        const response = res as any;
        this.data = response;
        this.electrics = this.data.ListofSuppliers;
        this.electricId = this.data.ListofSuppliers[0].elecID;
        this.uploadElectricId();
      });
  }
  uploadElectricId() {
    this.houseEnergy
      .uploadElectricId({
        email: this.appConstants.email,
        lead_id: this.leadId,
        action: "upload",
        elecID: this.electricId,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
        },
        (error) => {
          if (error && error && error.message) {
            this.toastr.error(error.message);
          }
        }
      );
  }
}
