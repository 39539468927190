<div>
  <!--<h4 class="step-heading">{{'Consumption.content' | translate}}</h4>-->
  <div class="d-flex head">
    <h4 class="step-heading">{{'Consumption.content' | translate}}</h4>
    <p class="float-right" style="color: gray; font-family: 'poppins';">{{'step' | translate }} 5/5</p>
  </div>
  <p></p>
  <div class="spacer-1"></div>
  <div class="form-container">
    <div class="row form-group d-flex flex-column ">
      <!--      <div class="row" >-->
      <!--        <label class="col-12 col-form-label">-->
      <!--          {{'Consumption.num-of-people' | translate}}-->
      <!--        </label>-->
      <!--      </div>-->
      <!--      <div class="row">-->
      <!--        <div class="col-12 col-form-label">-->
      <!--          <input type="number" class="form-control" [(ngModel)]="consumption.numPeople" (input)="extraInput($event)"-->
      <!--                 placeholder="{{'Consumption.num-of-people-placeholder' | translate}}" (mousewheel)="$event.preventDefault()">-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <div class="col-12 col-form-label">
          <div class="form-check">
            <input class="form-check-input" (change)="check()" [(ngModel)]="isSelected" checked
              value="AnnualConsumptionSelected" type="radio" name="isAnnualConsumptionSelected" id="flexRadioDefault1">
            <label class="form-check-label" for="flexRadioDefault1">
              {{'Consumption.yearly-consumption-new' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isSelected === 'AnnualConsumptionSelected'">
        <div class="col-12 mb-3 col-form-label">

          <input type="number" class="form-control " [ngModelOptions]="{standalone: true}"
            [(ngModel)]="consumptionNew.YearlyElecRequired" placeholder="{{'Consumption.yearly-consumption' | translate}}"
            (mousewheel)="$event.preventDefault()">
        </div>

      </div>

      <div class="row" *ngIf="isSelected === 'AnnualConsumptionSelected'">
        <label class="col-12 col-form-label ">
          <mat-slider thumbLabel [(ngModel)]="consumptionNew.YearlyElecRequired" [displayWith]="formatLabel"
            tickInterval="1000" step="5000" min="0" max="500000 " aria-label="unit(s)"></mat-slider>
        </label>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <div class="col-12 col-form-label">
          <div class="form-check">
            <input class="form-check-input" (change)="check()" [(ngModel)]="isSelected" value="MonthlyPriceSelected"
              type="radio" name="isMonthlyPriceSelected" id="flexRadioDefault2">
            <label class="form-check-label" for="flexRadioDefault2">
              {{'Consumption.monthly-electricity-cost' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isSelected === 'MonthlyPriceSelected'">
        <div class="col-12 mb-3 col-form-label">

          <input type="number" class="form-control " [ngModelOptions]="{standalone: true}"
            [(ngModel)]="consumptionNew.monthlyElecCost"
            placeholder="{{'Consumption.monthly-electricity-cost' | translate}}" (mousewheel)="$event.preventDefault()">
        </div>

      </div>

      <div class="row" *ngIf="isSelected === 'MonthlyPriceSelected'">
        <label class="col-12 col-form-label ">
          <mat-slider thumbLabel [(ngModel)]="consumptionNew.monthlyElecCost" [displayWith]="formatLabel2"
            tickInterval="50" step="50" min="0" max="5000 " aria-label="unit(s)"></mat-slider>
        </label>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label ">
          {{'Consumption.state' | translate}}
        </label>
      </div>

      <div class="row">
        <div class="col-12 col-form-label">
          <button *ngIf="stateList.length < 1"
            style="border: 1px solid gray; color: #007d32; background-color: ghostwhite"
            class="btn btn-block btn-light text-center spinner-button" type="button" disabled><span
              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <select *ngIf="stateList.length > 1" name="electricId" id="state" (change)="changeStateTariffDetails()"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedState" class="form-control">
            <option [ngValue]="undefined" hidden selected>{{'Consumption.state' | translate}}</option>
            <!--            <option  *ngIf="!stateList" [value]="0" hidden >select state</option>-->

            <option *ngFor="let state of stateList" [value]="state">{{state}}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12  text-center" *ngIf="consumptionNew.numPeople && consumptionNew.YearlyElecRequired">
        <button class="btn btn-primary w-100 continue-btn" (click)="submitForm()"><span>{{'Continue' |
            translate}}</span></button>
      </div>
      <!--<div class="col-6 text-center m-auto"><button class="btn btn-primary w-100" (click)="skip1()">   {{'Consumption.skip' | translate}}</button> </div>-->
    </div>
  </div>
</div>